@import "../../styles/utils";

.range-picker-panel-container {
  //padding: 2.4rem;
  background-color: white;

  @include medium {
    height: 100vh;
  }
}

.range-picker-panel-header {
  padding: 5rem 2.4rem 0 2.4rem;
}

.range-picker-panel-footer {
  padding: 2rem 2.4rem;
  text-align: right;
}

.range-picker-panel-header-title {
  font-size: 2rem;
  line-height: 2.4rem;
  color: rgba(55, 70, 101, 0.5);

  &:first-of-type {
    margin-bottom: 1rem;
  }

  span {
    margin-left: 0;
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: #DB0001;
  }
}

.range-picker-panel-one-way-button,
.range-picker-panel-save-button,
.range-picker-panel-cancel-button {
  font-size: 1.6rem;
  outline: none;
  border: none;
  background: none;
}

.range-picker-panel-one-way-button {
  color: #DB0001;
}

.range-picker-panel-save-button {
  color: #DB0001;
  font-weight: bold;
}

.range-picker-panel-cancel-button {
  color: #DB0001;
  font-weight: bold;
  margin-right: 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DB0001;
}