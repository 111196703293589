@import "../../../styles/utils";

.overview-container {
  position: relative;
  height: 100%;

  .overview {
    position: relative;
    padding: 1rem 10rem;
    height: 100%;
    overflow: scroll;

    h2 {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 27px;
      color: #F7F7F9;
    }

    h3 {
      color: white;
      font-weight: bold;
    }

    @include small {
      padding: 0 1rem;
    }
  }

  .information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 70px;

    .flight-info {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      width: 100%;
      height: 100%;
      background: #DB0001;
      color: #374665;
      border-radius: 5px;
      box-shadow: 0 3px 9px -1px rgba(133, 133, 133, 0.3);
      -webkit-box-shadow: 0 3px 9px -1px rgba(133, 133, 133, 0.3);
      -moz-box-shadow: 0 3px 9px -1px rgba(133, 133, 133, 0.3);

      p {
        font-size: 20px;
        color: white;
        margin-bottom: 0;

        @include small {
          font-size: 14px;
        }
      }

      h2 {
        font-weight: 700;
        font-size: 25px;
        color: #fff;

        @include small {
          font-size: 20px;
        }
      }
    }
  }

  .flight-info-oneWay {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    height: 100%;
    background: #DB0001;
    color: #374665;
    border-radius: 5px;
    box-shadow: 0 3px 9px -1px rgba(133, 133, 133, 0.3);
    -webkit-box-shadow: 0 3px 9px -1px rgba(133, 133, 133, 0.3);
    -moz-box-shadow: 0 3px 9px -1px rgba(133, 133, 133, 0.3);

    p {
      font-size: 20px;
      color: white;
      margin-bottom: 0;

      @include small {
        font-size: 14px;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 25px;
      color: #fff;
      margin-bottom: 0;
      margin-top: 10px;

      @include small {
        font-size: 20px;
      }
    }
  }

  .confirmation-code-info {
    background: #F3FAFB;
    width: 100%;
    color: #06A0B8;
    padding: 8px 24px 8px 24px;
    font-size: 12px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
    -webkit-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
    -moz-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);

    span {
      float: right;
    }
  }
}