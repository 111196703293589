@import "../../styles/utils";

.bookings-container {
  width: 80%;
  margin: 5rem auto;

  @include medium {
    width: 100%;
  }

  .right-column {
    margin-top: 8rem;
  }

  .bookings-center {
    text-align: center;
    margin-top: 14rem;
  }
}

.bookings-title {
  display: flex;
  align-items: center;

  height: 3rem;
  margin-bottom: 1rem;

  cursor: pointer;
  color: #0D3B66;
  font-size: 2.8rem;
  font-weight: bold;
}

.bookings-container .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow{
  color: rgba(13, 59, 102, 1);
  //font-size: 1.5rem;
}

.bookings-container .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background: #F7F7F9;
}

.bookings-container .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  padding-left: 12px;
}
