.noBookings {
  text-align: center;

  svg {
    transform: scale(3);
  }
  p {
    font-size: 2rem;
    line-height: 2.4rem;

    color: #374665;

    margin-top: 2.5rem;
    margin-bottom: 3rem;
  }
}

