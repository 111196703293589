@import "../../../styles/utils";

.terms-conditions {
  display: flex;
  float: right;

  @include medium {
    width: 100%;
    height: 70px;
  }

  @include small {
    padding: 0;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.terms-conditions-style {
  display: flex;

  a {
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline !important;

    &:hover {
      color: #DB0001 !important;
      text-decoration: underline;
    }

    @include medium {
      font-size: 1.5rem;
    }

    @include small {
      font-size: 1.4rem;
      text-decoration: underline !important;
    }
  }
}

.terms-conditions .ant-checkbox-wrapper {
  font-size: 16px;
  @include small {
    font-size: 12px;
  }
}

.ant-modal-content {
  height: 650px !important;
  overflow: scroll !important;
}

.ant-checkbox-wrapper {
  font-size: 12px;
  margin-right: 1rem;
}