@import "../../styles/utils";

.detailsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  background: #F7F7F9;
  border: 1px solid #E5E4E4;
  box-sizing: border-box;
  border-radius: 8px;
  // margin-top: 1rem;

  @include medium {
    flex-direction: column;
  }
}

.detailsContainerCard {
  flex: 1;
  flex-basis: 45%;
  min-width: 200px;
  margin: 1rem;
  padding: 3.5rem 2rem;

  border-radius: 1rem;
  background-color: white;
}

.header {
  font-size: 2.8rem;
  color: #DB0001;
  font-weight: bold;

  margin-bottom: 5rem;

  img {
    margin: 0 3rem;
  }
}

.columnHeader {
  font-weight: bold;
  font-size: 1.4rem;
  color: rgba(#374665, .5);

  margin-bottom: .2rem;
  margin-top: .2rem;

  white-space: nowrap;
}

.columnValue {
  font-weight: bold;
  font-size: 1.8rem;
  color: #DB0001;
}

.row {
  display: flex;
  margin-bottom: 3rem;

  .col {
    width: 50%;
  }
}

.qrCode {
  display: flex;
  align-items: center;
  color: #DB0001;

  img {
    width: 9rem;
    height: 9rem;
    margin-right: 1.2rem;
  }
}
