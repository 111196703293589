@import "../../styles/utils";

.passengerDetails {
  display: flex;
  background-color: whitesmoke;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 4px;
  margin-bottom: 10px;

  p {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
  }
}