@import "../../styles/utils";

.successResult {
  position: relative;
  padding: 48px 32px;

  background: #FFFFFF;

  // box-shadow: 0 .2rem .6rem rgba(0, 0, 0, 0.05);
  border-radius: .8rem;

  overflow: auto;
  text-align: center;
  max-width: 80vw;
  margin: 4rem auto;

  p {
    font-weight: bold;
    font-size: 2rem;

    color: #374665;
  }

  @include large {
    margin: 0;
    height: 100%;
    width: 100%;
    max-width: unset;
    padding: 6rem 0 0 0;
  }
}
.bookingDetailsWrapper {
  transition: transform 0.5s ease-in-out;
  transform: translateY(-20px); // This will move the details up when it appears
}


.successResultButtons {
  display: flex;
  flex-direction: column;
  max-width: 25%;
  margin: 3rem auto 0 auto;

  button {
    span {
      width: unset;
      max-width: unset;
    }
  }

  @include large {
    width: 100%;
    padding: 2.2rem;
    max-width: unset;
  }

  //here's the added code

  @include small {
    display: flex;
    flex-direction: column;
    max-width: 30%;
    padding: 0;
  }

  @include extra-small {
    display: flex;
    flex-direction: column;
    max-width: 30%;
    padding: 0;
  }
}
