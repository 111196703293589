.manual-payment-container {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 4px lightgray;
  padding: 2rem;
  margin: 8px;
  flex: 1;
  align-self: stretch;

  .content-container {
    text-align: left;

    p {
      font-size: 1.75rem;

      span {
        color: #06A0B8;
      }
    }
  }

  .button-container {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}