@import '../../styles/utils';

.flightPreviewTitle {
  font-size: 14px;
  margin-bottom: 1rem;
  margin-top: 4rem;
}

.top {
  display: flex;

  padding: 1rem 0;

  .airportName {
    font-size: 2.8rem;
    line-height: 3.3rem;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #F5F5F5;
  padding: 20px 47px;
  flex-wrap: wrap;
  border-radius: 5px;
  margin-top: 5rem;

  @include small {
    padding: 0;
  }

  @include medium {
    padding: 0;
  }
}

.flightPreviewDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 2;

  @include medium {
    padding: 10px;
  }

  @include small {
    padding: 0 10px;
  }

  .flightInfo {
    padding: 2rem 0;

    @include small {
      text-align: center;
      width: 100px;
    }

    //@include medium {
    //  text-align: center;
    //  width: 200px;
    //}
  }

  .horizontal {
    position: relative;
    flex: 1;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: #DB0001;
      }
    }

    .flightTime {
      position: absolute;
      top: 100%;
      left: 50%;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      transform: translate(-50%, 150%);

      @include large {
        transform: translate(-50%, 75%);
      }

      @include medium {
        display: none;
        font-size: 11px;
        transform: translate(-50%, 100%);
      }

      @include small {
        display: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      border-top: 1px solid #DB0001;
      width: 100%;
    }
  }

  .date {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: center;

    @include medium {
      font-size: 15px;
    }

    @include small {
      font-size: 15px;
    }
  }

  .time {
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    color: #DB0001;

    @include medium {
      font-size: 42px;
      line-height: 50px;
    }

    @include small {
      font-size: 36px;
      line-height: 48px;
    }
  }

  .airport {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    text-align: center;

    @include medium {
      font-size: 15px;
      font-weight: normal;
    }

    @include small {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .point {
    @include small {
      width: 7px;
      margin-top: 2px;
    }

    @include medium {
      width: 9px;
      margin-top: 2px;
    }
  }
}