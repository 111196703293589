@import "../../styles/utils";

.footer {
  //height: 100vh;
  background: #DB0001;
  padding: 50px 150px;
  margin-top: 70px;

  @include large {
    padding: 70px;
  }

  @include medium {
    padding: 50px;
  }

  @include small {
    padding: 20px;
    font-size: 12px;
  }

  ul {
    list-style: none;

    @include medium {
      padding: 0;
    }

    @include small {
      padding: 0 !important;
    }
  }

  li {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: white;
    margin-bottom: 10px;

    @include large {
      font-size: 16px;
    }

    @include medium {
      font-size: 14px;
    }

    a {
      color: white;
    }
  }

  h1 {
    font-size: 44px;
    font-weight: 700;
    color: white;
    line-height: 53px;
    margin-bottom: 40px;
  }

  h3 {
    color: white;

    @include large {
      font-size: 18px;
    }
    @include medium {
      font-size: 16px;
    }
  }

  p {
    width: 271px;
    font-size: 14px;
    color: white;
    margin-bottom: 40px;

    @include medium {
      width: 200px;
      font-size: 14px;
    }

    @include small {
      padding-bottom: 20px;
      justify-content: center;
      align-items: center;
    }
  }
}

.socialMedia {
  ul {
    display: flex;

    @include small {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  img {
    padding: 0 5px 10px 5px;
  }
}

.footerContent {
  margin-right: 200px;

  @include large {
    margin-right: 0;
  }
;

  @include medium {
    margin-right: 0;
  }
;
}

.footerPadding {
  margin-right: 50px;

  @include medium {
    margin-right: 0;
  }

  ul {
    @include large {
      padding: 0;
    }
    @include medium {
      padding: 0;
    }
    @include small {
      padding: 0;
    }
  }
}

.footerMain {
  display: flex;
  justify-content: space-around;

  img {
    margin: 0 10px 5px 0 !important;

    @include small {
      width: 60%;
    }
  }
}