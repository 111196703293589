$grey-dark: #374665;
$grey: #F7F7F9;

.overflow-hidden {
  overflow: hidden;
}

.ml-12 {
  margin-left: 1.2rem;
}

.mt-75 {
  margin-top: 7.5rem;
}

//Mobile
@mixin extra-small {
  @media (max-width: 415px) {
    @content;
  }
}

//Mobile
@mixin small {
  @media (max-width: 576px) {
    @content;
  }
}

//IPadAir
@mixin medium {
  @media (max-width: 820px) {
    @content;
  }
}

//IPadPro
@mixin large {
  @media (max-width: 1024px) {
    @content;
  }
}

//llaptopa 13"
@mixin mac13 {
  @media (min-width: 1025px) and (max-width: 1500px) {
    @content;
  }
}

@mixin extra-large {
  @media (min-width: 1025px) and (max-width: 2500px) {
    @content;
  }
}

//llaptopa 14"
@mixin mac14 {
  @media (min-width: 1501px) and (max-width: 1940px) {
    @content;
  }
}

//monitora
@mixin extra-extra-large {
  @media (min-width: 1941px) and (max-width: 2000px) {
    @content;
  }
}