@import "../../styles/utils";

.homepageImageCard {
  position: relative;
  padding: 3.2rem;
  overflow: hidden;
  border-radius: .8rem;
  box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.title {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 4.4rem;
  color: #FFFFFF;

  @include small {
    font-size: 2.2rem;
    line-height: 3.4rem;
  }
}

.status {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #FFFFFF;

  background: #06A0B8;
  border-radius: 40px;

  padding: .4rem 1.2rem;
  margin-left: 1.6rem;
}

.subtitle {
  font-size: 2rem;
  line-height: 2.4rem;
  color: #FFFFFF;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;

  svg {
    margin: 0 .5rem;
  }

  //flex-direction: column;
}

.small {
  .top {
    align-items: flex-start;
    flex-direction: column;

    .title {
      font-size: 2.6rem;
    }
  }
}
