@import "../../styles/utils";

.booking-details-container {
  width: 80%;
  margin: 0.5rem auto;

  @include medium {
    width: 100%;
  }

  .right-column {
    margin-top: 3.3rem;
  }
}
.bookingDetailsWrapper {
  transition: transform 0.5s ease-in-out;
  transform: translateY(-20px); // This will move the details up when it appears
}


.booking-details-title {
  // display: flex;
  align-items:center;
  // margin-left: 120px;
  height: 1rem;
  margin-bottom: 20px;
  margin-top: 30px;
  color: #DB0001;
  font-size: 2.8rem;
  font-weight: bold;

  svg {
    margin-right: 2rem;
    margin-left: 2rem;
    transform: scale(1);

    path {
      fill: #DB0001;
    }
  }
}

.expandable-row {
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s ease-in-out;

  &.expanded {
    max-height: 65rem;

    @include medium {
      max-height: 125rem;
    }
  }
}
