@import "../../styles/utils";

.flightBox {
  width: 135.78px;
  height: 70px;
  padding: 2px;
  cursor: pointer;
  border: 0.6px solid #7E7C7C;
  border-radius: 8px;
  text-align: center;

  @include large {
    width: 120px;
    //height: 100%;
    margin: 0 3px;
    padding: 10px;
  }

  @include small {
    height: 70px;
    padding: 5px 15px;
    margin: 0 10px !important;
  }

  @include mac13 {
    width: 120px;
    height: 70px;
    margin: 0 3px;
    padding: 10px;
  }

  &.disabled {
    cursor: default;
    background-color: #F5F5F5;
    border: 0.6px solid #7E7C7C;
    border-radius: 8px;

    //@include small {
    //  width: 80px;
    //}
  }

  &.selected {
    background: #DB0001;
    border-radius: 8px;
    border: #DB0001;
    //
    //@include small {
    //  width: 80px;
    //}

    .day {
      color: #fff;
    }

    .date {
      color: #fff;
    }

    .price {
      color: #fff;
      font-weight: 700;

    }
  }
}

.day {
  font-size: 13px;
  line-height: 16px;
  color: #000000;

  @include large {
    font-size: 10px;
  }

  @include mac13 {
    font-size: 12.5px;
    line-height: 14px;
  }

  @include small {
    font-size: 8px;
    line-height: 10px;
  }
}

.date {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #DB0001;

  @include large {
    line-height: 14px;
    font-size: 12px;
  }

  @include mac13 {
    font-size: 13px;
    line-height: 20px;
  }

  @include small {
    font-size: 12px;
    line-height: 14px;
  }
}

.price {
  font-size: 12px;
  line-height: 15px;
  color: #7E7C7C;

  @include mac13 {
    font-size: 14px;
    line-height: 20px;
  }

  @include small {
    font-size: 10px;
    line-height: 12px;
  }
}