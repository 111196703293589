@import "../../styles/utils";

.benefits {
  display: block;
  padding: 0 200px;
  margin: auto;
  margin-top: 30px;

  @include large {
    padding: 2.4rem 7rem 1.4rem 7rem;
  }

  @include small {
    padding: 0 65px;
  }

  .benefitsContent {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @include small {
      display: block;
      text-align: center;
    }
  }

  h1 {
    display: flex;
    justify-content: center;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 60px;

    @include small {
      font-size: 25px;
      text-align: center;
      font-weight: bold;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
    color: #7E7C7C;
  }

  .benefitsRow {
    display: flex;
    //margin-right: 45px;
    margin-bottom: 80px;
    max-width: 330px;

    @include small {
      display: block;
      margin-right: 0;
      margin-bottom: 30px;
    }

    img {
      width: 45px;
      height: 45px;
    }
  }

  .benefitsText {
    margin-left: 10px;
  }
}