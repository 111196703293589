@import "../../styles/utils";

.manual-payments-container {
  position: relative;
  height: 100%;
  padding-bottom: 1.5rem;
  margin-top: 4rem;

  h2 {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 27px;
    color: #374665;
  }

  .payments-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;

    @include small {
      flex-direction: column;
      overflow: auto;
    }
  }
}

.mb3 {
  margin-bottom: 1rem;
}