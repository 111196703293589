.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main {
  padding: 0px 160px 160px;
  margin: 2rem;
  font-size: 12px;
}

.mainTitle h4 {
  font-size: 1.85rem;
  font-weight: bold;
}

.logo {
  width: 300px;
  height: auto;
  padding-bottom: 2rem;
}

.barcode {
  text-align: center;
  border-bottom: 1px solid #C9C9C9;
  padding: 1rem 0;
}

.center {
  text-align: center;
  padding: 1rem 0;
}

.table {
  width: 100%;
}

.table tr > td {
  font-size: 1.2rem;
}

.table tbody tr {
  border-bottom: 1px dotted #C9C9C9;
}

.table tbody tr:last-child {
  border: none;
}

.table td:last-child {
  text-align: end;
}

.table tr {
  line-height: 2rem;
}

.title {
  font-weight: bold;
  border-bottom: 1px solid #C9C9C9;
}

.totalContainer {
  width: 50%;
  margin-top: 3rem;
  float: right;
}

.total {
  padding: 0.25rem 0;
}

.total p {
  flex: 1;
  text-align: end;
}

.total p:first-child {
  font-weight: bold;
}

.total p:last-child {
  border-bottom: 1px dotted #C9C9C9;
}

.fontBold {
  font-weight: bold;
}

.marginTop50 {
  margin-top: 50px;
}

.importantInfo {
  text-align: center;
  font-weight: bold;
  font-size: 0.85rem;
  border: 1px dotted #C9C9C9;
}

.banks {
  align-items: flex-start;
  gap: 5px;
}

.banks div {
  flex: 1;
  word-wrap: break-word;
}

.invoice {
  text-align: center;
  font-weight: bold;
}