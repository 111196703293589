@import "../../styles/utils";

.image {
  height: fit-content;
  @include mac13 {
    width: 80%;
  }
}

.homepage-header {
  height: 100%;
  position: relative;
  background-size: cover;
  //background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/kid-flying.png");
}

.intro {
  padding: 220px 0 0 180px;

  @include small {
    display: none;
  }
}

.introStart {
  max-width: 500px;
  border-radius: 10px;

  h1 {
    color: #DB0001;
    font-size: 40px;
    font-weight: bold;
  }

  //
  //@include small {
  //  display: none;
  //}
}

.introEnd {
  max-width: 500px;
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 80px;

  p {
    color: #DB0001;
    font-size: 18px;
  }

  @include small {
    margin-bottom: 50px;
  }
}

.homepage-offers {
  //padding: 8rem;

  @include medium {
    padding: 4rem;
  }

  @include small {
    padding: 0;
  }
}

.homepage-title {
  font-size: 3rem;
  line-height: 2.9rem;
  color: #374665;
  margin-bottom: 3rem;

  @include medium {
    font-size: 2.4rem;
    margin-bottom: 2.4rem;
  }
}

.offer-cards-container {
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50rem;

  @include large {
    grid-auto-rows: 40rem;
  }

  @include medium {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 30rem;
  }
}

.formItem {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid black;
  height: 60px;
  width: 501px;
  margin-top: 20px;

  @include medium {
    height: 50px;
    background-color: transparent;
    border: 1px solid #fff;
  }

  @include small {
    max-width: 380px;
  }

  @include extra-small {
    width: 310px !important;
  }

}

.homepage-form-radio-input {
  h1 {
    color: #DB0001;
    font-weight: bold;
    margin-bottom: 8px;
  }

  p {
    width: 248px;
    color: #7E7C7C;
    margin-bottom: 30px;
    font-size: 10px;
  }
}

.homepage-form {
  width: 890px;
  padding: 2.4rem 0 1.4rem 0;
  background: white;
  border-radius: 1rem;
  margin: auto;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.25);;

  @include large {
    width: 820px;
  }

  @include medium {
    background-color: #DB0001;
  }

  .ant-picker-large .ant-picker-input > input {
    font-size: 1.9rem;
    width: 180px;
    @include small {
      width: 100%;
      color: white !important;
      font-weight: bold !important;
    }
  }

  .homepage-form-input-passenger {
    width: 250px !important;
    margin-left: 25px !important;
    height: 60px;
    margin-right: 1px;
    padding: 8px 15px;
    border: 1px solid #000000;
    border-radius: 5px;


    @include medium {
      width: 500px !important;
      height: 50px;
      border: 1px solid #fff;
      padding: 5px 15px;
      background-color: transparent !important;
      margin-left: 0 !important;
    }

    @include small {
      width: 380px !important;
      margin-top: 20px;
      margin-left: 0 !important;
    }

    @include extra-small {
      width: 310px !important;
    }

    label {
      font-size: 12px;
      line-height: 14px;
      color: #DB0001;
      font-weight: 700;
      margin-bottom: 0;
      padding-left: 3rem;

      @include medium {
        color: white;
        //margin-left: 25px;
      }
    }

    .homepage-form-passenger {
      display: flex;
      justify-content: space-between;

      //.ant-select-arrow .anticon > svg {
      //  font-size: 12px;
      //  margin-left: -20px !important;
      //  color: #DB0001;
      //}
    }

    .passenger-icon {
      display: flex;
      justify-content: space-between;

      @include medium {
        width: 45%;

        span svg {
          display: none;
        }
      }

      @include small {
        width: 30% !important;

        span svg {
          display: none;
        }
      }

      span {
        font-size: 18px;
        color: #3b3b3b;
        //margin-left: 42px;
        //mobile passenger span
        @include medium {
          color: white;
          font-weight: bold;
        }

        @include small {
          color: white;
          font-weight: bold;
        }

        @include extra-small {
          margin-left: 10px;
        }
      }

      img {
        margin: -4px 10px 3px 0;
      }
    }
  }

  .test {
    display: flex;
    width: 250px;
    height: 60px;
    margin-right: 1px;
    border: 1px solid #000000;
    border-radius: 5px;
    align-items: center;

    @include medium {
      width: 500px;
      height: 50px;
      margin-bottom: 15px;
      border: 1px solid #fff;
      padding: 5px 15px;
    }

    @include small {
      width: 380px !important;
      margin-bottom: -1px;
    }

    @include extra-small {
      width: 310px !important;
    }

    img {
      height: 18px;
      width: 18px;
      margin: -4px 10px 5px 0;

      @include large {
        margin: -4px 10px 5px 0;
      }

      @include medium {
        margin: 0;
      }

      @include small {
        margin: 0;
      }
    }

    .alignment {
      position: absolute;
      margin: 20px 0 0 12px;

      @include extra-small {
        margin: 7px 0 0 0;
      }

      @include small {
        margin: 0 0 0;
      }

      @include medium {
        margin: 0 0 0 0;
      }
    }

    .homepage-form-input {
      position: relative;
      width: 200px;

      @include medium {
        width: 400px;
        height: 100% !important;

      }

      label {
        font-size: 12px;
        line-height: 14px;
        color: #DB0001;
        font-weight: 700;
        margin-bottom: 0;
        margin-left: 4rem;

        @include medium {
          color: #fff;
        }
      }

      .switch-destinations-button {
        position: absolute;
        bottom: 11px;
        left: 125%;
        transform: translateX(-50%);
        width: 2.2rem;
        height: 2.2rem;
        //padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        //z-index: 999;
        outline: none;
        border: none;
        border-radius: 1.1rem;
        background-color: #DB0001;
        //box-shadow: 0 0 .4rem rgba(0, 0, 0, 0.2);
        padding: 3px;

        @include medium {
          width: 4.2rem;
          height: 4.2rem;
          border-radius: 2.1rem;
          left: 111%;
          bottom: -31px;
          background-color: #DB0001;
          border: 1px solid white;
        }

        @include small {
          left: 96%;
          top: 30px;
          width: 3.2rem;
          height: 3.2rem;
        }
      }
    }
  }

  .airport {
    display: flex;
    justify-content: space-between;
  }

  .homepage-form-select-input {
    display: flex;
    align-items: center;
    width: 230px;

    @include medium {
      width: 250px;
    }

    .ant-select-selection-item {
      font-size: 18px;
      color: #3b3b3b;
      width: 190px;
      padding-left: 4rem;

      @include medium {
        color: #F7F7F9;
        font-weight: bold;
      }
    }

    .ant-select-arrow {
      top: 44%;
      margin-top: -8px;
      margin-right: -3px;
      color: #DB0001;
      font-size: 12px;
    }
  }

  .homepage-form-search-button {
    width: 250px;
    height: 60px !important;
    background: #DB0001 !important;
    margin-left: 25px !important;
    margin-top: 20px;

    @include medium {
      width: 500px;
      height: 50px !important;
      margin-left: 0 !important;
    }

    @include small {
      width: 380px;
      background-color: #F7F7F9 !important;
      color: #DB0001 !important;
    }

    @include extra-small {
      width: 310px !important;
    }
  }

  .homepage-form-search-button:disabled {
    @include small {
      width: 380px !important;
      color: whitesmoke !important;
    }

    @include extra-small {
      width: 310px !important;
    }
  }

  .ant-radio-group {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
  }

  .ant-radio-button-wrapper {
    border-radius: 6px;
    margin-right: 15px;
    display: flex;
    border: 1px solid #DB0001 !important;
    font-size: 12px;
    font-weight: bold;
    color: #DB0001;

    @include small {
      border: 1px solid white !important;
      color: white;
      background: #DB0001;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 0;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #DB0001;

    @include medium {
      border: 1px solid white !important;
    }

    @include small {
      background: white !important;
      color: #DB0001;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
}

.home-page-popup-button {
  font-size: 1.6rem;
  outline: none !important;
  border: none;
  background: none;
}

.home-page-popup-secondary-button {
  color: black;
  font-weight: bold;
}

.home-page-popup-primary-button {
  color: #DB0001;
  font-weight: bold;
}

.homepage-form-select-input {
  &.ant-select-dropdown {
    width: 400px !important;
    border-radius: 10px;
    padding-top: 5px;
    overflow-y: auto;
    z-index: 9999;
  }
}

.airports-option {
  &.airports-option-disabled {
    opacity: .6;
  }
}

.services {
  padding: 80px 40px 101px;
  text-align: center;

  .services-cards {
    display: flex;
    justify-content: space-evenly;
  }
}

.contact {

}

.contact-us {
  margin: 9rem 20rem 4rem 20rem;
  color: black;

  @include small {
    margin: 4rem 3.3rem 3rem;
  }

  @include medium {
    margin: 9rem 5rem 5rem;
  }

  h1 {
    font-size: 55px;
    margin-bottom: 35px;
    color: black;
    font-weight: bold;
  }

  @include medium {
    font-size: 2.4rem;
  }
  @include small {
    font-size: 2.4rem;
  }
}

p {
  font-size: 1.4rem;
}

a {
  color: red;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
  margin: 9rem 20rem 11rem;

  img {
    @include medium {
      display: none;
    }
    @include small {
      display: none;
    }
    @include mac13 {
      margin: 0;
      margin-top: 35px;
    }
  }

  @include medium {
    display: block;
  }

  @include small {
    display: block;
    margin: auto;
    margin-bottom: 3rem;
  }

  @include mac13 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.contact-form {
  width: 407px;

  @include medium {
    width: 50% !important;
    margin: auto;
  }
  @include small {
    width: 300px;
    margin: auto;
  }
}

.homepage-form {
  position: relative;
  justify-content: space-evenly;
  padding: 40px 30px;
  z-index: 9999;

  @include large {
    z-index: 0;
  }

  @include medium {
    z-index: 0;
    padding: 40px 10px;
  }

  @include small {
    z-index: 0;
    padding: 30px 15px;
  }


  .ant-form {
    width: 407px;
    @include medium {
      width: 370px;
    }
    @include small {
      width: 300px;
    }
    @include mac13 {
      margin-bottom: 3rem;
    }
  }
}

.contact-form .ant-form-item-label > label {
  font-weight: bold;
  font-size: 1.6rem;
  color: #374665;
  margin-top: 2.7rem;
  margin-bottom: 0.9rem;
  height: 2rem;

  @include small {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 0.4rem;
  }
}

.contact-info {
  margin: 20px 180px;

  p {
    font-size: 1.6rem;
    color: #374665;
  }
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.values {
  display: flex;
  height: 8rem;
  background-color: #F7F7F9;
  justify-content: space-evenly;
  align-items: center;

  .values-title {
    font-weight: bold;
    font-size: 18px;
    color: #374665;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 27px;
      margin-right: 5px;
    }
  }

  @include medium {
    height: unset;
    flex-direction: column;

    .values-title {
      width: 100%;
      padding: 1.4rem 0;
      text-align: center;
      font-size: 16px;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(#374665, .1);
      }
    }
  }
}

@include medium {

  .ant-select-dropdown {
    position: fixed;
  }

  .custom-date-range-picker-dropdown {
    position: fixed;
  }

  .homepage-form {
    width: 90%;
    margin: auto auto 6rem auto;

    @include small {
      width: 95%;
      top: 20px;
      border-radius: 4px;
      margin: auto auto 4rem auto;
    }
  }

  .homepage-form-select-input {
    &.ant-select-dropdown {
      top: 0 !important;
      left: 0 !important;
      width: 100vw !important;
      height: 100vh !important;
      border-radius: 0;
      padding-top: 0;
    }
  }

  .ant-picker-dropdown-range {
    left: 0 !important;
    top: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    padding: 0;

    .ant-picker-range-wrapper {
      width: 100vw;
      min-width: unset !important;

      .ant-picker-panel-container {
        width: 100%;
        border-radius: 0;

        .ant-picker-panels {
          width: 100%;
          //flex-direction: column;

          .ant-picker-panel {
            &:nth-child(1) {
              .ant-picker-header {
                .ant-picker-header-view {
                  text-align: center;
                }
              }
            }

            &:nth-child(2) {
              position: absolute;
              right: 0;

              .ant-picker-body {
                display: none;
              }

              .ant-picker-header {
                .ant-picker-header-view {
                  display: none;
                }
              }
            }

            .ant-picker-decade-panel,
            .ant-picker-year-panel,
            .ant-picker-quarter-panel,
            .ant-picker-month-panel,
            .ant-picker-week-panel,
            .ant-picker-date-panel,
            .ant-picker-time-panel {
              width: 100%;
            }
          }

          .ant-picker-date-panel {
            width: 100%;

            .ant-picker-content {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.homepage-offers {
  margin: 8rem 9rem 9rem;
  position: relative;
  //z-index: -999;

  @include medium {
    margin: 0;
  }

  @include small {
    margin: 0;
  }

  .homepage-offers-title {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    line-height: 22px;
    align-items: center;

    a {
      color: black;
      margin-left: 1rem;
    }

    img {
      margin-bottom: 2px;
    }

    @include small {
      font-size: 12px;
      padding: 12px;
    }
  }

  .homepage-view-destinations {
    display: flex;
    font-size: 28px;
    line-height: 34px;
    color: #000000;

    @include medium {
      font-size: 2.4rem;
      margin-bottom: 0.4rem;
    }

    @include small {
      font-size: 1.4rem;
    }
  }

  .homepage-offers-offer {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 35rem;

    @include large {
      grid-auto-rows: 40rem;
    }

    @include medium {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: 30rem;
    }
  }
}

.flex {
  display: flex;

  @include medium {
    display: block;
  }
}

.ant-picker-suffix {
  margin-left: 8px !important;
}

.ant-select-dropdown .homepage-form-select-input .ant-select-dropdown-placement-bottomRight {
  @include small {
    min-width: 250px !important;
    left: 0 !important;
    top: 0 !important;
  }
}
