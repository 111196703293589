@import "../../styles/utils";

.aboutUsContent {
  img {
    height: 100%;
    width: 100%;
  }

  .aboutUsAlignment {
    padding: 45px 150px;

    @include small {
      padding: 45px;
    }

    @include medium {
      padding: 45px;
    }

    h1 {
      font-size: 55px;
      margin-bottom: 35px;
    }

    h2 {
      font-size: 40px;
      margin: 3rem 0;
    }

    p {
      font-size: 20px;
    }
  }

  .benefits {
    display: flex;

    @include medium {
      display: block;
    }

    .benefitsRow {
      display: flex;
      margin-right: 10px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .benefitsText {
      margin-left: 10px;

      p {
        font-size: 12px;
        color: darkgray;
        margin-right: 10px;
      }
    }
  }
}



