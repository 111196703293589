@import './assets/styles/colors.scss';
@import './assets/styles/global.scss';
@import '~antd/dist/antd.css';
@import './assets/styles/ant-design.scss';


body {
  font-family: 'Product-Sans', sans-serif;
}

.opacity-60 {
  opacity: .6;
}

/* Background colors */
.app-bg-primary { background-color: $sky; }

/* Text colors */
.app-text-main { color: #374665; }
.app-text-secondary { color: #06A0B8; }

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #7c7e7e !important;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 350px !important;
  outline: none;
}
