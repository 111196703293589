.booking {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 11px -1px rgba(133, 133, 133, 0.4);
  -webkit-box-shadow: 0px 3px 11px -1px rgba(133, 133, 133, 0.4);
  -moz-box-shadow: 0px 3px 11px -1px rgba(133, 133, 133, 0.4);

  .booking-flight {
    padding: 16px;
    width: 100%;
    background: #FFFFFF;
    color: #374665;
    border-radius: 8px 8px 0 0;

    p {
      font-size: 12px;
      color: #374665;
      opacity: 0.5;
    }

    h2 {
      font-weight: 700;
      color: #374665;
      margin-left: 28px;
    }
  }
}

.confirmation-code {
  background: #F3FAFB;
  width: 100%;
  color: #06A0B8;
  padding: 8px 24px 8px 24px;
  font-size: 12px;
  border-radius: 0 0 8px 8px;

  span {
    float: right;
  }
}

.booking-passengers {
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  box-shadow: 0px 3px 11px -1px rgba(133, 133, 133, 0.4);
  -webkit-box-shadow: 0px 3px 11px -1px rgba(133, 133, 133, 0.4);
  -moz-box-shadow: 0px 3px 11px -1px rgba(133, 133, 133, 0.4);

  p {
    font-size: 1.6rem;
    font-weight: bold;
    color: #374665;
    opacity: 0.6;
  }

  h2{
    font-size: 2rem;
    color: #374665;
  }
}

.booking-passengers .anticon{
  color: #06A0B8;
  font-size: 1.2rem;
}

.booking-flight .anticon{
  color: #374665;
  font-size: 2rem;
  margin-right: 10px;
  margin-top: 2px;
}
