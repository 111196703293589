@font-face {
  font-family: 'Product-Sans';
  font-style:  normal;
  font-weight: 400;
  src: url("../assets/fonts/Product-Sans-Regular.ttf") format("opentype");
}

@font-face {
  font-family: 'Product-Sans';
  font-style:  italic;
  font-weight: 400;
  src: url("../assets/fonts/Product-Sans-Italic.ttf") format("opentype");
}

@font-face {
  font-family: 'Product-Sans';
  font-style:  normal;
  font-weight: 700;
  src: url("../assets/fonts/Product-Sans-Bold.ttf") format("opentype");
}

@font-face {
  font-family: 'Product-Sans';
  font-style:  italic;
  font-weight: 400;
  src: url("../assets/fonts/Product-Sans-Bold-Italic.ttf") format("opentype");
}



